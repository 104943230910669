<template>
    <div class="flex items-center gap-1 cursor-pointer hover:bg-blacks-100 hover:text-white transform px-2">
        <span
            @click="backItem($event.target)"
            class="i-ic:twotone-keyboard-arrow-down transform rotate-90 hover:text-white text-xl"
        ></span>

        <span @click="backItem($event.target)" class="flex-1 font-medium py-3"> Quay lại </span>
    </div>
</template>

<script setup lang="ts">
const backItem = (event) => {
    event.parentNode.parentNode?.classList.remove('is-active')
}
</script>

<style></style>
